import React from 'react'
import { FaUserTie } from "react-icons/fa";
import { GiHumanPyramid } from "react-icons/gi";
import { FaBusinessTime } from "react-icons/fa";
import { BsCalendar2CheckFill } from "react-icons/bs";





export default function Statistics() {
    return (
        <>
            <section className="statistics">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="leftwhiteheading">
                                <h2>We're thriving and building better products</h2>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 col-6 mb-3">
                                    <div className="stats-card">
                                        <div className="statshead">
                                            <FaUserTie className="stat-icon" />
                                            <span>Happy Clients</span></div>
                                        
                                        <div className="statdigit">

                                            <h3>437 +</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-6 mb-3">
                                    <div className="stats-card">
                                        <div className="statshead">
                                            <GiHumanPyramid className="stat-icon" />
                                            <span>Dedicated Hands</span></div>
                                        
                                        <div className="statdigit">

                                            <h3>237 +</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-6 mb-3">
                                    <div className="stats-card">
                                        <div className="statshead">
                                            <FaBusinessTime className="stat-icon" />
                                            <span>Working Since</span></div>
                                        
                                        <div className="statdigit">

                                            <h3>2013</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-6 mb-3">
                                    <div className="stats-card">
                                        <div className="statshead">
                                            <BsCalendar2CheckFill className="stat-icon" />
                                            <span>Completed Projects</span></div>
                                        
                                        <div className="statdigit">

                                            <h3>578 +</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
