import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer'
import { BsFillTelephoneFill, BsMapFill, BsEnvelopeFill } from "react-icons/bs";
import Headerbanner from '../components/Headerbanner';





export default function Contact() {
  return (
    <>
      <Headerbanner />
      <section className="breadcrumb">
        <div className="breadtitle">
          <h2 className="page-title">Contact Us</h2>
        </div>
        <div className="bread-bar">
          <ul className="breadlist">
            <li><NavLink className="breadlinks" to="/" >Home</NavLink></li>
            <li><NavLink className="breadlinks" to="/Contact" >Contact Us</NavLink></li>

          </ul>
        </div>
      </section>
      <section className="Contactus">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-50">
              <div className="contact-card">
                <div className="contactimg">
                  <img src="assets/images/callus.webp" alt="" />
                </div>
                <a href="tel: +919810970393">
                  <BsFillTelephoneFill />
                  <div className="callcard">
                    <span>Call Us on</span>
                    <h4>+91 9810970393</h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 mb-50">
              <div className="contact-card">
                <div className="contactimg">

                  <img src="assets/images/mailus.webp" alt="" />
                </div>
                <a href="mailto: extrutechviraaj@gmail.com">
                  <BsEnvelopeFill />
                  <div className="callcard">
                    <span>Mail Us on</span>
                    <h4>extrutechviraaj@gmail.com</h4>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 mb-50">
              <div className="contact-card">
                <div className="contactimg">
                  <img src="assets/images/reachus.webp" alt="" />
                </div>
                <a href="https://goo.gl/maps/JXuXJVaGvB6gxALb6">
                  <BsMapFill />
                  <div className="callcard">
                    <span>Reach us at</span>
                    <h4>SLF Mall Sector 30, Faridabad</h4>
                  </div>
                </a>
              </div>
            </div>


          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="contactleft">
                <form method="post" action="https://viraajextrutech.com/leads/form.php" className="contactpage row">
                 
                  <div class="col-lg-6 form-floating">
                    <input required type="text" class="form-control" id="floatingInput" placeholder="name"/>
                      <label for="floatingInput">Your Name</label>
                  </div>
                  <div class="col-lg-6 form-floating">
                    <input required type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                      <label for="floatingInput">Email</label>
                  </div>
                  <div class="col-lg-6 form-floating">
                    <input required type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                      <label for="floatingInput">Contact Number</label>
                  </div>
                  <div class="col-lg-6 form-floating">
                    <input required list='productList' type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                      <label for="floatingInput">Product</label>
                      <datalist required id="productList">
                      <option value="Modular Clean Room Wall Partition Panels">Modular Clean Room Wall Partition Panels</option>
                      <option value="Clean Room Modular Flush Doors">Clean Room Modular Flush Doors</option>
                      <option value="Walk on Clean Room Modular Ceiling Panel">Walk on Clean Room Modular Ceiling Panel</option>
                      <option value="Clean Room Glass View Panels">Clean Room Glass View Panels</option>
                      <option value="Modular Laboratory Furniture">Modular Laboratory Furniture</option>
                      <option value="Clean Room Equipments">Clean Room Equipments</option>
                    </datalist>
                  </div>
               <div class="col-lg-12 form-floating">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                      <label for="floatingInput">Any Special Message</label>
                  </div>
                  <button name="submit" className="submitbtn" type="submit">Send Enquiry
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"></path>
                    </svg></button>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contactright">

              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mapviraaj">
        <h2>Directions to Reach Us</h2>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3660.4919290017992!2d77.31776688321287!3d28.45642235499701!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce764088526ab%3A0x7eec710ba06ff2f1!2sViraaj%20extrutech!5e1!3m2!1sen!2sin!4v1680075907313!5m2!1sen!2sin" width="600" height="450"></iframe>
      </section>
      <Footer />
    </>
  )
}
