import React from "react";
import { NavLink } from "react-router-dom";
import { MdContacts } from "react-icons/md";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { ImHome } from "react-icons/im";
import { FaUserAlt } from "react-icons/fa";

export default function Headerbanner() {
  return (
    <>
      <div className="mainbanner">
        <header className="headerbanner">
          <div className="headerlogo">
            <NavLink className="logo" to="/">
              <img src="assets/images/logo.png" alt="" />
            </NavLink>
          </div>
          <div className="header links">
            <ul className="nav-list">
              <li>
                <NavLink className="Navlink" to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink className="Navlink" to="/About">
                  About
                </NavLink>
              </li>
              <li>
                <NavLink className="Navlink" to="/Products">
                  Products
                </NavLink>
              </li>
              <li>
                <NavLink className="Navlink" to="/Projects">
                  Projects
                </NavLink>
              </li>
              <li>
                <NavLink className="Navlink" to="/Contact">
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="headerbtn">
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#headerpopup"
              className="enquirybtn"
            >
              Raise Enquiry
            </button>
          </div>
        </header>
      </div>
      <div
        className="modal fade"
        id="headerpopup"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Send Enquiry
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="headerform row">
                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Name"
                      className="input-field"
                      type="text"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Name
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Number"
                      className="input-field"
                      type="tel"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Number
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Email"
                      className="input-field"
                      type="email"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Email
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      list="productList"
                      placeholder="Select Product"
                      className="input-field"
                      type="text"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Product
                    </label>
                    <datalist id="productList" 
                      required>
                      <option value="Modular Clean Room Wall Partition Panels">
                        Modular Clean Room Wall Partition Panels
                      </option>
                      <option value="Clean Room Modular Flush Doors">
                        Clean Room Modular Flush Doors
                      </option>
                      <option value="Walk on Clean Room Modular Ceiling Panel">
                        Walk on Clean Room Modular Ceiling Panel
                      </option>
                      <option value="Clean Room Glass View Panels">
                        Clean Room Glass View Panels
                      </option>
                      <option value="Modular Laboratory Furniture">
                        Modular Laboratory Furniture
                      </option>
                      <option value="Clean Room Equipments">
                        Clean Room Equipments
                      </option>
                    </datalist>
                    <span className="input-highlight"></span>
                  </div>
                </div>
                <div class="col-lg-12 group-form">
                  <button name="submit" type="submit" class="submitbtn">
                    Send Enquiry
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"></path>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="miniheader">
        <NavLink to="/Home">
          <img className="minilogo" src="assets/images/logo.png" alt="" />
        </NavLink>
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#headerpopup"
          className="enquirybtn"
        >
          Raise Enquiry
        </button>
      </div>
      <div className="mininav">
        <NavLink className="minilinks" to="/Home">
          <ImHome /> Home{" "}
        </NavLink>
        <NavLink className="minilinks" to="/About">
          <FaUserAlt /> About{" "}
        </NavLink>
        <NavLink className="minilinks" to="/Products">
          <BsFillBoxSeamFill /> Products{" "}
        </NavLink>
        <NavLink className="minilinks" to="/Contact">
          <MdContacts /> Contact{" "}
        </NavLink>
      </div>
    </>
  );
}
