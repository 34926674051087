import React from 'react'

export default function Cores() {
    return (
        <>
            <section className="whyus">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="whyusleft centerit">
                                <div className="whytext">
                                    <div className="leftheading">
                                        <span className="subhead">
                                            Testimonials</span>
                                        <h2>What they
                                            <span className="boldheading"> Says</span>
                                        </h2>
                                        <p>We are producers of Insulated
                                            Sandwich panels and solution
                                            providers for any building
                                            with walls and a roof. We
                                            connect expertise across services
                                            , markets and geographies to deliver
                                            transformative outcomes.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="whyusright">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img src="assets/images/icons/perseverance.png" />
                                                    <h3>Perseverance</h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <p>
                                                    We will continue to put the best
                                                    We are Never settled.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img src="assets/images/icons/trust.png" />
                                                    <h3>Trust</h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <p>
                                                      We trust on Our Works and our team.
                                                      We are trustable for more than 200+ Brands.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img src="assets/images/icons/ownership.png" />
                                                    <h3>Ownership</h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <p>
                                                    We will own the processes we perform.
                                                    We takes 100% responsiblities of our Works
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img src="assets/images/icons/transparency.png" />
                                                    <h3>Transparency</h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <p>
                                                        We encourage open debate 
                                                        where the best ideas win and Clear Transparency to Clients.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img src="assets/images/icons/empowerment.png" />
                                                    <h3>Empowerment</h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <p>
                                                        We empower people to make
                                                        decisions with a bias for action.
                                                        We Believes in Executions.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img src="assets/images/icons/enterprising.png" />
                                                    <h3>Enterprising</h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <p>
                                                        We shall take desired amount of
                                                        calculated risks to take the
                                                        company to the next level.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img src="assets/images/icons/respect.png" />
                                                    <h3>Respect</h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <p>
                                                        The diversity of our workforce for
                                                        everyone with dignity and respect
                                                        regardless of status ,gender & religion.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img src="assets/images/icons/focus.png" />
                                                    <h3>Customer Focus</h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <p>
                                                        We put our customers at the center of our focus and initiatives with the objective of providing them
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <img src="assets/images/icons/teamwork.png" />
                                                    <h3>Teamwork</h3>
                                                </div>
                                                <div className="flip-card-back">
                                                    <p>
                                                        We actively share information
                                                        and ideas, enthusiastically
                                                        working to make those around
                                                        us better.
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
