import React from 'react'
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay, EffectCreative } from "swiper";

export default function Testimonials() {
    return (
        <>
            <section className="testimonials">
                <div className="container-fluid m-0">
                    <div className="row p-0">
                        <div className="col-md-4 p-0">
                            <div className="testimleft">
                            <div className="leftheading">
                            <span className="subhead">Testimonials</span>
                            <h2>What they  <span className="boldheading">Says</span>
                            </h2>
                            </div>
                                <p>Viraaj Extrutech is here from 2011, Since that Era we have earned A lot or Graces and Relations.
                                    Our Team is dedicated to add new Happy Clients in our Portfolio .
                                </p>
                            </div>
                        </div>
                        <div className="col-md-8 p-0">
                        <Swiper
                          autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        loop={true}
         grabCursor={true}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        modules={[ Pagination, Navigation, Autoplay, EffectCreative]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="testimonal-board">
                                <div className="testimonial-card">
                                    <FaQuoteLeft className="quoteleft" />
                                    <h3 className="growleft-1">Keep up the excellent work. Just what I was
                                        looking for.</h3>
                                    <p className="growleft-2">am completely blown away. I would gladly
                                        pay over 600 dollars for Cold Room Manufacturing
                                        .I love your system. This is simply unbelievable!
                                        I can't say enough about Cold Room Manufacturing</p>
                                    <div className="testim-bio">
                                        <div className="testimpic">
                                            <img className="bouncein" src="https://www.banksandlloyd.com/wp-content/uploads/2018/10/testimonial-icon-smaller.png" width="100px" alt="" />
                                        </div>
                                        <div className="testim-name growleft-1">
                                            <h5>Ravikant Khanna</h5>
                                            <span>CEO at Khanna Namkeen </span>
                                        </div>
                                    </div>
                                    <FaQuoteRight className="quoteright" />
                                </div>
                            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonal-board">
                                <div className="testimonial-card">
                                    <FaQuoteLeft className="quoteleft" />
                                    <h3 className="growleft-1">Keep up the excellent work. Just what I was
                                        looking for.</h3>
                                    <p className="growleft-2">am completely blown away. I would gladly
                                        pay over 600 dollars for Cold Room Manufacturing
                                        .I love your system. This is simply unbelievable!
                                        I can't say enough about Cold Room Manufacturing</p>
                                    <div className="testim-bio">
                                        <div className="testimpic">
                                            <img className="bouncein" src="https://www.banksandlloyd.com/wp-content/uploads/2018/10/testimonial-icon-smaller.png" width="100px" alt="" />
                                        </div>
                                        <div className="testim-name growleft-1">
                                            <h5>Ravikant Khanna</h5>
                                            <span>CEO at Khanna Namkeen </span>
                                        </div>
                                    </div>
                                    <FaQuoteRight className="quoteright" />
                                </div>
                            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonal-board">
                                <div className="testimonial-card">
                                    <FaQuoteLeft className="quoteleft" />
                                    <h3 className="growleft-1">Keep up the excellent work. Just what I was
                                        looking for.</h3>
                                    <p className="growleft-2">am completely blown away. I would gladly
                                        pay over 600 dollars for Cold Room Manufacturing
                                        .I love your system. This is simply unbelievable!
                                        I can't say enough about Cold Room Manufacturing</p>
                                    <div className="testim-bio">
                                        <div className="testimpic">
                                            <img className="bouncein" src="https://www.banksandlloyd.com/wp-content/uploads/2018/10/testimonial-icon-smaller.png" width="100px" alt="" />
                                        </div>
                                        <div className="testim-name growleft-1">
                                            <h5>Ravikant Khanna</h5>
                                            <span>CEO at Khanna Namkeen </span>
                                        </div>
                                    </div>
                                    <FaQuoteRight className="quoteright" />
                                </div>
                            </div>
        </SwiperSlide>
  
      </Swiper>
                           
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
