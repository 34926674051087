import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer'
import Headerbanner from '../components/Headerbanner'

export default function Products() {
  return (
    <>
    <Headerbanner/>
    <section className="breadcrumb">
                    <div className="breadtitle">
                <h2 className="page-title">Products</h2>
                </div>
                <div className="bread-bar">
                    <ul className="breadlist">
                        <li><NavLink className="breadlinks" to="/" >Home</NavLink></li>
                        <li><NavLink className="breadlinks" to="/Products" >Products</NavLink></li>
                    
                    </ul>
                </div>
        </section>
        <section className="products">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-6 mb-5">
                <div className="product-card-2">
                  <div className="productpic">
                    <img src="assets/images/products/wall-partition.jpg" width="100%" alt="" />
                  </div>
                  <div className="product-text">

                    <h3><NavLink className="cardlink" to="/Wallpartition"> Wall Celing Panels</NavLink></h3>
                    <p>Viraaj Extrutech is a foremost manufacturer and supplier of clean room
                     panels whose name is well established in India as well as overseas for 
                     its ready to install range of the modular clean room wall partition panels.</p>
                         <NavLink className="mainbtn" to="/Wallpartition"> View More</NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-5">
                <div className="product-card-2">
                  <div className="productpic">
                    <img src="assets/images/products/flush-doors.jpg" width="100%" alt="" />
                  </div>
                  <div className="product-text">

                    <h3><NavLink className="cardlink" to="/Flushdoors">Modular Flush Doors</NavLink></h3>
                    <p>We provide Custom Made Solutions for our Clients in Clean Room Modular Flush Doors. Viraaj Extrutech India counted among the most reputed manufacturer and supplier for Clean Room Modular Flush Doors.</p>
                         <NavLink className="mainbtn" to="/Flushdoors"> View More</NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-5">
                <div className="product-card-2">
                  <div className="productpic">
                    <img src="assets/images/products/ceiling-panel.jpg" width="100%" alt="" />
                  </div>
                  <div className="product-text">

                    <h3><NavLink className="cardlink" to="/Ceilingpanel">Walk-in Ceiling Panel</NavLink></h3>
                   <p>
                   Just like the wall partition, clean room ceiling is equally crucial to maintain the controlled environment and therefore Viraaj Extrutech.
                   W are providing Best Modular Walk in Ceiling Panel.
                   </p>     
                   <NavLink className="mainbtn" to="/Ceilingpanel"> View More</NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-5">
                <div className="product-card-2">
                  <div className="productpic">
                    <img src="assets/images/products/glass-panel.jpg" width="100%" alt="" />
                  </div>
                  <div className="product-text">

                    <h3><NavLink className="cardlink" to="/Glasspanels">Glass View Panels</NavLink></h3>
                    <p>Viraaj extrutech is actively engrossed in manufacturing a broad range of the glazed view panels also famous as glass view panel, available in single glaze as well as a double glaze as per the end user requirement.</p>
                         <NavLink className="mainbtn" to="/Glasspanels"> View More</NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-5">
                <div className="product-card-2">
                  <div className="productpic">
                    <img src="assets/images/products/lab-furniture.jpg" width="100%" alt="" />
                  </div>
                  <div className="product-text">

                    <h3><NavLink className="cardlink" to="/Labfurniture">Laboratory Furniture</NavLink></h3>
                    <p>The toxic fumes, vapors and dust are common byproducts in laboratories that needs to be controlled for the safety of the personnel and that you can achieve. We Provide Service and Production for Modular Labs.</p>
                         <NavLink className="mainbtn" to="/Labfurniture"> View More</NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mb-5">
                <div className="product-card-2">
                  <div className="productpic">
                    <img src="assets/images/products/equipment.jpg" width="100%" alt="" />
                  </div>
                  <div className="product-text">

                    <h3><NavLink className="cardlink" to="/Equipments">Clean Rooms Equipments</NavLink></h3>
                    <p>Viraaj Extrutech a prominent manufacturer and supplier of clean room equipment in
                     India has made up a world class quality range of the clean room equipment that 
                     strongly meets all the clean room standards.</p>
                         <NavLink className="mainbtn" to="/Equipments"> View More</NavLink>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </section>
        <Footer/>
    </>
  )
}
