import React from 'react'

export default function Serviceshome() {
    return (
        <>
            <section className="serviceshome">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="serviceleft">
                                <img src="assets/images/abouthome.jpg" width="100%" className="imagestyle" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="rightservice">
                                <div className="leftheading">
                                    <span className="subhead">Covings</span>
                                    <h2>Facts & Series of <span className="boldheading"> Covings</span></h2>
                                </div>
                                <p>We are a leading Manufacturer of Cold Storage Coving Projects and Fully Automatic Cold Storage Coving from Faridabad, India. Cold Storage Coving Projects.</p>
                                    <ul className="specslist">
                                        <li>We have multiple series of profile and accessories that are used in Clean and Cold rooms.
</li>
                                        <li>Currently, we have R50, R70, R90 and R100 series of profiles with attachments and fittings of each series.
</li>
                                        <li>We have almost 300 colour options to cater to multiple segments from pharma companies to hospitals.
</li>
                                        <li>We maintain a minimum stock: up to 30,000 RMT of each profile.
</li>
                                        <li>We provide different parameter’s test certificate.
</li>
                                    </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
