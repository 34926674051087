import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay, EffectFade } from "swiper";


export default function Mainslider() {
    return (
        <>
            <Swiper
            navigation={true} 
            centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        effect={"fade"}
                modules={[ Pagination, Navigation, Autoplay, EffectFade ]}
                className="mainslider"
                spaceBetween={0}
                loop={true}
                slidesPerView={1}>
                <SwiperSlide>
                    <div style={{
                        background: 'url("../assets/images/slider/bg-main.jpg")',
                    }} className="container-fluid p-0 m-0">
                        <div className="row centerit p-0 m-0">
                            <div className="col-lg-7">
                                <div className="sliderleft">
                                    <div className="slider-heading">
                                        <span className="slideheading">Welcome to Viraaj Extrutech</span>
                                        <h2 className="slideheading">We Provide Complete Cold Rooms Services</h2>
                                    </div>
                                    <div className="slidertextcta slidetext">
                                        <p>We earnestly work to provide our customer
                                         a seamless experience in purchasing products
                                          from us.</p>
                                          <a href="" className="mainbtn slidelink">Contact Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="sliderright">
                                    <img src="/assets/images/slider/main-1.jpg " />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={{
                        background: 'url("../assets/images/slider/bg-main2.jpg")',
                    }} className="container-fluid p-0 m-0">
                        <div className="row centerit p-0 m-0">
                            <div className="col-lg-7">
                                <div className="sliderleft">
                                    <div className="slider-heading">
                                        <span className="slideheading">Welcome to Viraaj Extrutech</span>
                                        <h2 className="slideheading">We Provide Complete Cold Rooms Services</h2>
                                    </div>
                                    <div className="slidertextcta slidetext">
                                        <p>We earnestly work to provide our customer
                                         a seamless experience in purchasing products
                                          from us.</p>
                                          <a href="" className="mainbtn slidelink">Contact Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="sliderright">
                                    <img src="/assets/images/slider/slide-2.jpg " />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={{
                        background: 'url("../assets/images/slider/bg-main3.jpg")',
                    }} className="container-fluid p-0 m-0">
                        <div className="row centerit p-0 m-0">
                            <div className="col-lg-7">
                                <div className="sliderleft">
                                    <div className="slider-heading">
                                        <span className="slideheading">Welcome to Viraaj Extrutech</span>
                                        <h2 className="slideheading">We Provide Complete Cold Rooms Services</h2>
                                    </div>
                                    <div className="slidertextcta slidetext">
                                        <p>We earnestly work to provide our customer
                                         a seamless experience in purchasing products
                                          from us.</p>
                                          <a href="" className="mainbtn slidelink">Contact Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="sliderright">
                                    <img src="/assets/images/slider/slide-3.jpg " />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={{
                        background: 'url("../assets/images/slider/bg-main4.jpg")',
                    }} className="container-fluid p-0 m-0">
                        <div className="row centerit p-0 m-0">
                            <div className="col-lg-7">
                                <div className="sliderleft">
                                    <div className="slider-heading">
                                        <span className="slideheading">Welcome to Viraaj Extrutech</span>
                                        <h2 className="slideheading">We Provide Complete Cold Rooms Services</h2>
                                    </div>
                                    <div className="slidertextcta slidetext">
                                        <p>We earnestly work to provide our customer
                                         a seamless experience in purchasing products
                                          from us.</p>
                                          <a href="" className="mainbtn slidelink">Contact Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="sliderright">
                                    <img src="/assets/images/slider/slide-4.jpg " />
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}
