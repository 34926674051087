import React from 'react'

import { SiYourtraveldottv } from 'react-icons/si';
import { RiTeamFill } from 'react-icons/ri';


import { MdOutlinePermDataSetting } from 'react-icons/md';
import { RiCustomerService2Fill } from 'react-icons/ri';
import { GiReceiveMoney } from 'react-icons/gi';
import { BsSmartwatch } from 'react-icons/bs';








export default function Whyus() {
  return (
    <>
        <section className="whyus">
            <div className="container"><div className="leftheading">
            <span className="subhead">Our Core Values</span>
            <h2>Values we <span className="boldheading"> Delivers</span>
            </h2></div>
                <div className="row mt-50">
                <div className="col-lg-4 col-sm-6 mb-3">
                        <div className="whycard">
                            <SiYourtraveldottv/>
                            <h3>ON TIME DELIVERY</h3>
                            <p>To us on time delivery is vital and with the efficiency and integrity, we have been able to meet the promised delivery date in India as well as overseas.</p>

                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-3">
                        <div className="whycard">
                            <RiTeamFill/>
                            <h3>MODERN TECHNOLOGY</h3>
                            <p>We believe to walk with the innovation,
                             hence constantly adopt modern technology 
                             to deliver the world class quality.</p>

                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-3">
                        <div className="whycard">
                            <MdOutlinePermDataSetting/>
                            <h3>OUR EXPORT</h3>
                            <p>Our products are widely export and acknowledged by the foreign countries and our global expansion in African countries and Asian countries is the testimony of the same.</p>

                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-3">
                        <div className="whycard">
                            <GiReceiveMoney/>
                            <h3>STRONG TEAMWORK</h3>
                            <p>The collaborative approach of our designer, engineers, quality analyst, technicians, marketing and the logistic team is a foundation of our splendid success and growth.</p>

                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-3">
                        <div className="whycard">
                            <RiCustomerService2Fill/>
                            <h3>AFTER SALES SERVICE</h3>
                            <p>Our round the clock customer service and support aftermath of a sale is our major focus to ensure 100% customer satisfaction.</p>

                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-3">
                        <div className="whycard">
                            <BsSmartwatch/>
                            <h3>COMPETITIVE PRICE</h3>
                            <p>Our ISO 9001: 2015 manufacturing practices, helping us to propose world class quality at the competitive price in the market.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
