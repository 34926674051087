import React from 'react'

export default function Aboutfounder() {
    return (
        <>
            <section className="founder">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="leftheading">
                                <span className="subhead">
                                    From Director Table
                                </span>
                                <h2>Meet Our <span className="boldheading"> Director</span></h2>
                            </div>
                            <p>We founded our company Viraaaj Extrutech india in 2013.
                                We are the foremost suppliers of modular clean and cold
                                room accessories. Our infrastructure is equipped with
                                modern amenities and installations that aid in the process
                                of smooth business functioning.</p>
                            <p className="mt-2">
                                Our infrastructure is equipped with modern amenities and
                                 installations that aid in the process of smooth business 
                                 functioning.
                                Each product, labeled under our name, is designed to help
                                 in simulating laboratory conditions with utmost dexterity.

                            </p>
                            <p>
                            At Viraaaj Extrutech, all the products are manufactured as per
                             GMP/ cGMP regulations, hence our every product ensures consistent,
                              acceptable product quality and safety of the premise, process
                               and people and therefore is perfect for any clean room, 
                               laboratory, hospital, food industry application requirements. 
                               Further, all our products are made from the world class 
                               quality GI skin pass material that bestows best in class
                                quality, durability and performance throughout life, 
                                whereas its water, chemical and corrosion resilient
                                 properties help maintain contamination free environment.
                            </p>
                            <div className="stylepara">
                                <p> "Instant Actions are Best things for Grow More "</p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="founderpic">
                                <img src="assets/images/jayant-surana.png" width="100%" alt="" />
                                <div className="foundername">
                                    <h3>Jayant Surana</h3>
                                    <h5>Director | Viraaj Extrutech</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
