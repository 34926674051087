import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer'
import Headerbanner from '../components/Headerbanner'
import Vision from '../components/Vision'
import Statistics from '../components/Statistics'
import Whyus from '../components/Whyus'
import Clientele from '../components/Clientele'
import Aboutfounder from '../components/Aboutfounder'
import Whoweare from '../components/Whoweare'
import { Helmet } from 'react-helmet'

export default function About() {
  return (
    <>

          <Helmet>
            <title>About Us | Viraaj Extrutech</title>
          </Helmet>
            <Headerbanner/>
            <section className="breadcrumb">
                    <div className="breadtitle">
                <h2 className="page-title">About Us</h2>
                </div>
                <div className="bread-bar">
                    <ul className="breadlist">
                        <li><NavLink className="breadlinks" to="/" >Home</NavLink></li>
                        <li><NavLink className="breadlinks" to="/About" >About Us</NavLink></li>
                    
                    </ul>
                </div>
        </section>
        <Aboutfounder/>
        <Vision/>
        <Whoweare/>
        <Statistics/>
        <Whyus/>
        <Clientele/>
        <Footer/>
    </>
  )
}
