import React from 'react'
import Abouthome from '../components/Abouthome'
import Applications from '../components/Applications'
import Clientele from '../components/Clientele'
import Footer from '../components/Footer'
import Headerbanner from '../components/Headerbanner'
import Mainslider from '../components/Mainslider'
import Productslider from '../components/Productslider'
import Serviceshome from '../components/Serviceshome'
import Statistics from '../components/Statistics'
import Testimonials from '../components/Testimonials'
import Usp from '../components/Usp'
import Cores from '../components/Cores'

export default function Home() {
  return (
  <>
    <Headerbanner/>
    <Mainslider/> 
    <Usp/>
    <Abouthome/>
    <Statistics/>
    <Testimonials/>
    <Applications/>
    <Serviceshome/>
    <Cores/>
    <Productslider/>
    <Clientele/>
    <Footer/>

  </>
  )
}
