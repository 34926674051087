import React from 'react'








import { GiCirclingFish, GiMedicinePills, GiMeat, GiFireFlower, GiMilkCarton } from 'react-icons/gi';
import { RiFridgeFill } from 'react-icons/ri';
import { MdSevereCold } from 'react-icons/md';
import { IoFastFood } from 'react-icons/io5';

export default function Applications() {
    return (
        <>
            <section className="applications">
                <div className="container">
                <div className="centerheading">
                <span className="subhead">Our Application Areas</span>
                <h2>Industries We <span className="boldheading"> Serve</span>
                </h2>
                </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-2 col-xs-1">
                            <div className="app-card">
                                        <MdSevereCold/>
                                        <h4>Cold Storage (Fruites)</h4>
                                    </div>
                                   
                        </div>
                        <div className="col-md-3 col-sm-2 col-xs-1">
                            <div className="app-card">
                                        <RiFridgeFill/>

                                        <h4>Refrigerated Warehouse</h4>
                                   
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-2 col-xs-1">
                            <div className="app-card">
                                        <GiCirclingFish/>

                                        <h4>Sea Food Factory</h4>
                                   
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-2 col-xs-1">
                            <div className="app-card">
                                        <GiMeat/>

                                        <h4>Meat and Poultry Industry</h4>
                                   
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-2 col-xs-1">
                            <div className="app-card">
                                        <GiFireFlower/>

                                        <h4>Floriculture Industry</h4>
                                   
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-2 col-xs-1">
                            <div className="app-card">
                                        <GiMilkCarton/>

                                        <h4>Dairy Industry</h4>
                                   
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-2 col-xs-1">
                            <div className="app-card">
                                        <IoFastFood/>

                                        <h4>Food Processing Industry</h4>
                                   
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-2 col-xs-1">
                            <div className="app-card">
                                        <GiMedicinePills/>

                                        <h4>Pharmaceutical Industry</h4>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
