import React from 'react'
import { IoTelescopeSharp, IoDiamondSharp } from "react-icons/io5";

import { GiBullseye } from "react-icons/gi";


export default function Vision() {
    return (
        <section className="vision">
            <div className="container">
                <div className="centerheading">
                    <span className="subhead">Vision, Mission & Values</span>
                    <h2>Glance at our <span className="boldheading"> Visions</span>
                    </h2></div>
                <div className="row mt-50">
                    <div className="col-lg-4">
                        <div className="vision-card">
                        <div className="visionheading">
                            <IoTelescopeSharp />
                            <h3>Vision</h3>
                        </div>
                            <p>To become a leading supplier of clean and cold room items.
                                To be the best solution provider for accessories of clean
                                 and cold room by providing unmatched quality products at
                                  the most competitive prices .</p>



                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="vision-card">
                        <div className="visionheading">
                             <GiBullseye />
                            <h3>Mission</h3>
                        </div>
                           
                            <p>We earnestly work to provide our customer a seamless
                                experience in purchasing products from us.
                                Committed to bring top-notch products for clean and cold industries
                                by adhering to the international standards.</p>



                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="vision-card transparent-border">
                           <div className="visionheading">
                           <IoDiamondSharp />
                            <h3>Values</h3>
                           </div>
                            <p>We provide Quality over Quantity. We are into Value for Costs.
                             We are Serving more than 200+ Brands with our Full 
                             of Dedication and Faith.A Quality Management System has
                              been implemented by the organization.</p>



                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
