import React from 'react'

export default function Whoweare() {
  return (

    <>
      <section className="whoweare">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="leftwho">
                <div className="leftheading">
                  <span className="subhead">Brief Viraaj Extrutech</span>
                  <h2>Know About
                    <span className="boldheading">Viraaj</span>
                  </h2></div>

                <p>We are producers of Insulated Sandwich panels and 
                solution providers for any building with walls and a 
                roof. We connect expertise across services, markets and
                 geographies to deliver transformative outcomes.</p>
                 <p className="mt-2">
                 At Viraaj Extrutech, all the products are manufactured as per GMP/ cGMP regulations, hence our every product ensures consistent, acceptable product quality and safety of the premise, process and people and therefore is perfect for any clean room, laboratory, hospital, food industry application requirements. Further, all our products are made from the world class quality GI skin pass material that bestows best in class quality, durability and performance throughout life, whereas its water, chemical and corrosion resilient properties help maintain contamination free environment.
                 </p>
              </div>

            </div>
            <div className="col-lg-6">
              <div className="imagecont">
                <img width="100%" src="https://stockarea.io/blogs/wp-content/uploads/2021/05/ImageForArticle_155111.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
