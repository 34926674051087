import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer'
import Headerbanner from '../components/Headerbanner'
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

export default function Projects() {
    return (
        <>
            <Headerbanner />
            <section className="breadcrumb">
                <div className="breadtitle">
                    <h2 className="page-title">Projects</h2>
                </div>
                <div className="bread-bar">
                    <ul className="breadlist">
                        <li><NavLink className="breadlinks" to="/" >Home</NavLink></li>
                        <li><NavLink className="breadlinks" to="/Projects" >Projects</NavLink></li>

                    </ul>
                </div>
            </section>
            <section className="galleryviraaj">

                <LightGallery
                    speed={500}
                    plugins={[ lgThumbnail, lgZoom ]}>
                    <a href="assets/images/gallery/project-1.webp">
                        <img alt="gallery images Viraaj Extrutech" src="assets/images/gallery/project-1.webp" />
                    </a>
                    <a href="assets/images/gallery/project-2.webp">
                        <img alt="gallery images Viraaj Extrutech" src="assets/images/gallery/project-2.webp" />
                    </a>
                    <a href="assets/images/gallery/project-3.webp">
                        <img alt="gallery images Viraaj Extrutech" src="assets/images/gallery/project-3.webp" />
                    </a>
                    <a href="assets/images/gallery/project-4.webp">
                        <img alt="gallery images Viraaj Extrutech" src="assets/images/gallery/project-4.webp" />
                    </a>
                    <a href="assets/images/gallery/project-5.webp">
                        <img alt="gallery images Viraaj Extrutech" src="assets/images/gallery/project-5.webp" />
                    </a>
                    <a href="assets/images/gallery/project-6.webp">
                        <img alt="gallery images Viraaj Extrutech" src="assets/images/gallery/project-6.webp" />
                    </a>
                    <a href="assets/images/gallery/project-7.webp">
                        <img alt="gallery images Viraaj Extrutech" src="assets/images/gallery/project-7.webp" />
                    </a>
                    <a href="assets/images/gallery/project-8.webp">
                        <img alt="gallery images Viraaj Extrutech" src="assets/images/gallery/project-8.webp" />
                    </a>



                </LightGallery>
            </section>
            <Footer />
        </>
    )
}
