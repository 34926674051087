import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay } from "swiper";
import "swiper/css/navigation";
import Headerbanner from "../components/Headerbanner";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { BsFillSendFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";

export default function Labfurniture() {
  return (
    <>
      <Helmet>
        <title>Modular Laboratory Furniture</title>
      </Helmet>
      <Headerbanner />
      <section className="breadcrumb">
        <div className="breadtitle">
          <h2 className="page-title">
          Modular Laboratory Furniture
          </h2>
        </div>
        <div className="bread-bar">
          <ul className="breadlist">
            <li>
              <NavLink className="breadlinks" to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className="breadlinks" to="/Products">
                Products
              </NavLink>
            </li>
            <li>
              <NavLink className="breadlinks" to="/Wallpartition">
              Modular Laboratory Furniture
              </NavLink>
            </li>
          </ul>
        </div>
      </section>
      <section className="product-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="singleslider">
                <Swiper
                  navigation={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                  }}
                  loop={true}
                  modules={[Navigation, Autoplay]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="proimg">
                      <img
                        src="assets/images/products/furniture-1.webp"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="proimg">
                      <img
                        src="assets/images/products/furniture-2.webp"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="proimg">
                      <img
                        src="assets/images/products/furniture-3.webp"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="product-info">
                <div className="leftheading">
                  <span className="subhead">Lab Furniture</span>
                  <h2>
                    Modular Laboratory{" "}
                    <span className="boldheading"> Furniture </span>
                  </h2>
                </div>
                <p>
                  In order to maintain the clean room standards, it is essential
                  to opt for the right material when it comes to modular
                  laboratory furniture and is what Viraaj Extrutech, a leading
                  manufacturer and supplier of modular laboratory furniture in
                  India promises to provide. Our high quality range of the
                  modular lab furniture is made from the top notch quality GI
                  skin pass material that is robust against corrosion hence
                  provides you rust free performance throughout its life.
                </p>
                <button
                  type="button"
                  className="productquery"
                  data-bs-toggle="modal"
                  data-bs-target="#querypopup"
                >
                  Send Enquiry
                  <BsFillSendFill />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product-description">
        <div className="container">
          <Tabs
            defaultActiveKey="Description"
            transition={true}
            id="noanim-tab-example"
          >
            <Tab eventKey="Description" title="Desription">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Modular Laboratory Furniture</h2>
                </div>
                <p>
                  Our modular lab furniture has gained wide acceptance in
                  pharmaceuticals, food processes, health care, clean room,
                  operating rooms and the industries where a controlled
                  environment is paramount. We also do the custom job to meet
                  the specific requirement of our customers and ensure the best
                  accommodation. The entire range is prefabricated and examined
                  in house only ahead of the installation at the site, making
                  the installation task expedite and scrap free.
                </p>
                <p>
                  Further, all the modular lab furniture is designed and
                  manufactured keeping the international quality standards to
                  the first priority to fit with your laboratory/ clean room
                  requirements. The design is carried out keeping the
                  convenience, maximum storage and safety in mind, whereas CNC
                  press fitting technology is used to avoid welding and gaps and
                  obtain an absolutely smooth surface. Such a smooth surface
                  enables easy cleaning and maintenance, results in no bacteria
                  or pest development possibilities inside. This press fitting
                  technology also enables easy interchanging of modules, thus
                  makes it absolutely user friendly.
                </p>
              
                <div className="underheading">
                  <h3>Custom Solution is Our Standard</h3>
                </div>
                <p>
                  We are considered among the few of the companies that use GI
                  skin pass material, a material that is extensively being used
                  in foreign countries to maintain the high quality standards at
                  the clean room and laboratories. The integration of the
                  different modules is achieved by using the topmost quality SS
                  screw, nuts/bolts to avoid erosion/corrosion and maintain the
                  high level of hygiene. That means our modular lab furniture
                  strongly meets all the laboratory standards that is essential
                  to maintain the controlled environment.
                </p>
              </div>
            </Tab>
            <Tab eventKey="Technical" title="Technical ">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Technical </h2>
                </div>
                <table className="detail-table">
                  <tbody>
                    <tr>
                      <th>Build Quality</th>
                      <td>Aluminium</td>
                    </tr>
                    <tr>
                      <th>Material Brand</th>
                      <td>Various Such as Tata.</td>
                    </tr>
                    <tr>
                      <th>Standard Size</th>
                      <td>Depends on Requirement</td>
                    </tr>
                    <tr>
                      <th>Other Sizes</th>
                      <td>
                        1200 x 600mm; 915 x 610mm, 600 x 600mm; 595 x 595mm
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab eventKey="Highlights" title="Highlights">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Highlights</h2>
                </div>
                <p>
                  We already have earned the trust of customers belonging from
                  Gujarat, Hyderabad, Mumbai, Assam, Himachal Pradesh and
                  foreign countries like Nigeria, Algeria, Ghana, Egypt, Kenya,
                  Turkey, Mexico, Brazil, Malaysia, Bangladesh, Nepal, Vietnam,
                  Thailand and envisioning to expand in every nook of the world.
                </p>
              </div>
            </Tab>
            <Tab eventKey="Our Range" title="Our Range">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Benefits</h2>
                </div>
                <ul className="specslist">
                  <li>Sitting Height Cabinets</li>
                  <li>
                    Better Flexibility, Durability, Aesthetic and Elegant
                    appearance with the use of Aluminium Profile.
                  </li>
                  <li>Standing Height Cabinets</li>
                  <li>Fume Hood Base Cabinets</li>
                  <li>Wall Cabinets</li>
                  <li>Sterile Garment Cabinet / Clean Room Storage Cabinet</li>
                  <li>Tall Cabinets</li>
                </ul>
                <p>
                  Furnish your laboratories with our top quality range of the
                  modular lab furniture, available at the best prices all across
                  India and abroad.
                </p>
              </div>
            </Tab>
          </Tabs>
        </div>
      </section>
      <section className="keywords">
        <div className="container">
          <div className="leftheading">
            <span className="subhead">Keywords</span>
            <h2>
              Keyword Related to <span className="boldheading"> Product</span>
            </h2>
          </div>
          <div className="keywordslist">
            <h4>Clean Room Puf Panel</h4>
            <h4>Polyurethane Sandwich Panels</h4>
            <h4>Rockwool Sandwich Panel Manufacturers in India</h4>
            <h4>Insulated Sandwich Panel Manufacturers in India</h4>
            <h4>PUF Sandwich Panel Manufacturer in India</h4>
            <h4>Clean room Puf Sandwich Panels</h4>
            <h4>Clean Room Sandwich Panels</h4>
            <h4>Clean Room Insulated Panel</h4>
            <h4>Clean Room Insulation Panels price</h4>
            <h4>Clean Room Panel Manufacturers in Faridabad</h4>
            <h4>Clean Room Panel Manufacturers in Haryana</h4>
            <h4>Clean Room Panel Manufacturers in Delhi - NCR</h4>
            <h4>Clean Room Panel Manufacturers in india</h4>
            <h4>Clean Room Puf Panels Suppliers</h4>
            <h4>Clean Room Panel Price</h4>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="querypopup"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Quick Product Enquiry Form
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="product row">
                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Name"
                      className="input-field"
                      type="text"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Name
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Number"
                      className="input-field"
                      type="tel"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Number
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Email"
                      className="input-field"
                      type="email"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Email
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      value="Modular Laboratory Furniture"
                      disabled
                      className="selectedproduct input-field"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12 group-form">
                  <button name="submit" type="submit" className="submitbtn">
                    Send Enquiry
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"></path>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
