import React from 'react'
import { BsFillTelephoneFill, BsFillPinMapFill, BsFillEnvelopeFill } from "react-icons/bs";
import { NavLink } from 'react-router-dom';




export default function Footer() {
    return (
        <>

            <footer className="mainfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="ctafooter">
                                <div className="ctaline">
                                    <h2>Lets shift to Modern Cold Rooms</h2>
                                    <NavLink className="secandbtn" to="/Contact">Conact Us</NavLink>
                                </div>
                                <div className="ctaimg">
                                    <img src="assets/images/footer-cta.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-lg-5 col-12 p-0">
                            <div className="footerbio">
                                <img src="assets/images/logo.png" alt="" />
                                <p>We are the foremost suppliers of modular clean and cold room accessories.
                                    Our infrastructure is equipped with modern amenities and installations 
                                    that aid in the process of smooth business functioning</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6">
                            <div className="footernav">
                                <h3>Quick Links</h3>
                                <ul className="footerlinks">
                                <li>
                                      <NavLink to="/Home">
                                        Homepage
                                      </NavLink> 
                                    </li>
                                    <li>
                                      <NavLink to="/About">
                                      About
                                      </NavLink> 
                                    </li>
                                    <li>
                                      <NavLink to="/Products">
                                      Products
                                      </NavLink> 
                                    </li>
                                    <li>
                                      <NavLink to="/Projects">
                                      Projects
                                      </NavLink> 
                                    </li>
                                    <li>
                                      <NavLink to="/Contact">
                                      Contact
                                      </NavLink> 
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-6">
                            <div className="footernav">
                                <h3>Products</h3>
                                <ul className="footerlinks">
                                <li>
                                      <NavLink to="/Wallpartition">
                                      Wall Partition Panels
                                      </NavLink> 
                                    </li>
                                    <li>
                                      <NavLink to="/Flushdoors">
                                      Modular Flush Doors
                                      </NavLink> 
                                    </li>
                                    <li>
                                      <NavLink to="/Ceilingpanel">
                                      Walk On Ceiling Panels
                                      </NavLink> 
                                    </li>
                                    <li>
                                      <NavLink to="/Glasspanels">
                                      Glass View Panels
                                      </NavLink> 
                                    </li>
                                    <li>
                                      <NavLink to="/Labfurniture">
                                      Laboratory Furniture
                                      </NavLink> 
                                    </li>
                                    <li>
                                      <NavLink to="/Equipments">
                                      Clean Room Equipments
                                      </NavLink> 
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-12">
                            <div className="reachus">
                                <h3>Reac Us</h3>
                                <ul className="reachlist">
                                    <li>
                                        <a href="tel: +919810970393">
                                            <BsFillTelephoneFill />
                                            +919810970393
                                        </a>
                                    </li>

                                    <li>
                                        <a href="mailto:extrutechviraaj@gmail.com">
                                            <BsFillEnvelopeFill />
                                            extrutechviraaj@gmail.com
                                        </a>
                                    </li>

                                    <li className="addressli">
                                       
                                            <BsFillPinMapFill />
                                           
                                            Floor, Office No. 18, Ground, 33, SLF Mall St, I P Colony, Sector 30, Faridabad, Haryana 121004
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <hr className="footerhr"/>
                <div className="copyright">
                    <div className="copytag">
                        Copyright © {new Date().getFullYear()} All Right Reserved
                    </div>

                </div>

            </footer>
        </>
    )
}
