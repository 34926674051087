import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay } from "swiper";
import "swiper/css/navigation";
import Headerbanner from "../components/Headerbanner";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { BsFillSendFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";

export default function Glasspanels() {
  return (
    <>
      <Helmet>
        <title>Glazed Vision Panels</title>
      </Helmet>
      <Headerbanner />
      <section className="breadcrumb">
        <div className="breadtitle">
          <h2 className="page-title">
            Clean Room Glass View Panels
          </h2>
        </div>
        <div className="bread-bar">
          <ul className="breadlist">
            <li>
              <NavLink className="breadlinks" to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className="breadlinks" to="/Products">
                Products
              </NavLink>
            </li>
            <li>
              <NavLink className="breadlinks" to="/Glasspanels">
              Clean Room Glass View Panels
              </NavLink>
            </li>
          </ul>
        </div>
      </section>
      <section className="product-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="singleslider">
                <Swiper
                  navigation={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                  }}
                  loop={true}
                  modules={[ Navigation, Autoplay ]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="proimg">
                      <img
                        src="assets/images/products/glasspanel-1.webp"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="proimg">
                      <img
                        src="assets/images/products/glasspanel-2.webp"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="proimg">
                      <img
                        src="assets/images/products/glasspanel-3.webp"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                 
                </Swiper>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="product-info">
                <div className="leftheading">
                  <span className="subhead">Clean Room Glass View Panels</span>
                  <h2>
                    Glazed Vision   <span className="boldheading"> Panels </span>
                  </h2>
                </div>
                <p>
                  In order to provide one stop solution, Viraaj Extrutech, a well established manufacturer and supplier is actively engrossed in manufacturing a broad range of the glazed view panels also famous as glass view panel, available in single glaze as well as a double glaze as per the end user requirement. Just like wall panels, the offered glass view panels are made as per the international quality regulations at our in house unit situated in Faridabad.
                </p>
                <button
                  type="button"
                  className="productquery"
                  data-bs-toggle="modal"
                  data-bs-target="#querypopup"
                >
                  Send Enquiry
                  <BsFillSendFill />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product-description">
        <div className="container">
          <Tabs
            defaultActiveKey="Description"
            transition={true}
            id="noanim-tab-example"
          >
            <Tab eventKey="Description" title="Desription">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Clean Room Glass View Panels</h2>
                </div>
                <p>
                  We as a manufacturer of the glazed view panels are glad to introduce our well liked range of the single glazed view panel that is apposite for partition wall as well as any other masonry wall. The glazed part of the panel is made from a single piece of the glass, whereas the framework is made from the aluminum profile accompanied with aluminum angles to bestow notable durability, which can be fixed on either side of the wall.
                </p>
                <p>
                  Our clean room glass view panels are effective to provide adequate insulation with the glass view vision and are suitable for the door as well as a wall installation. Further, it's easy to wash and a uniform surface helps you maintain the utmost level of cleanliness and hygiene, essential in clean room. We also offer customized range of the single glazed view panel as per provided height, width and thickness requirement.
                </p>
              </div>
            </Tab>
            <Tab eventKey="Technical" title="Single Glazed Panel">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Single Glazed Panel</h2>
                </div>
                <p>We as a manufacturer of the glazed view panels are glad to introduce our well liked range of the single glazed view panel that is apposite for partition wall as well as any other masonry wall. The glazed part of the panel is made from a single piece of the glass, whereas the framework is made from the aluminum profile accompanied with aluminum angles to bestow notable durability, which can be fixed on either side of the wall.</p>
                <p className="mt-3">Our clean room glass view panels are effective to provide adequate insulation with the glass view vision and are suitable for the door as well as a wall installation. Further, it's easy to wash and a uniform surface helps you maintain the utmost level of cleanliness and hygiene, essential in clean room. We also offer customized range of the single glazed view panel as per provided height, width and thickness requirement.</p>
              </div>
            </Tab>
            <Tab eventKey="Highlights" title="Double Glazed Panel">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Double Glazed Panel</h2>
                </div>
            <p>
            To meet your high insulation requirement with a glazed view panels, Viraaj Extrutech a manufacturer and supplier of view panel has brought forth a truly versatile range of the double glazed view panels, which is suitable for partition wall and also other masonry walls.  The modern design of this clean room view panel enables easy fitting on any size of the wall without any efforts. This double glazed view panel is constructed from two vacuum separated pieces of glass to bestow the best possible insulating barrier, whereas the framework is made from the aluminum profile combined with the aluminum angles to obtain the robust structure, just like single glazed panels.


            </p>
            <p className="mt-3">
            The strong insulating barrier of this double glazed view panel helps effectively maintain the temperature level inside the clean room and thus increase energy efficiency. Further, the water and chemical resistant property of these windows enable easy washing and cleaning, essential to maintain the controlled environment in the clean room. Our clean room view panels are available in extensive size options as well as custom job is also available upon request.
            </p>
            <p className="mt-3">
            Our Both series of the glazed view panels/ glass view panels has engraved a strong position in several cities and countries for its superior performance, which incorporates Gujarat, Hyderabad, Mumbai, Assam, Himachal Pradesh and foreign countries like Nigeria, Algeria, Ghana, Egypt, Kenya, Turkey, Mexico, Brazil, Malaysia, Bangladesh, Nepal, Vietnam, Thailand.
            </p>
              </div>
            </Tab>
            <Tab eventKey="Benefits" title="Benefits">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Benefits</h2>
                </div>
                <ul className="specslist">
                  <li>
                    All panels are insulated with polyurethane/ PIR / Rock Wool
                    insulation and as per customers requirements.
                  </li>
                  <li>
                    Better Flexibility, Durability, Aesthetic and Elegant
                    appearance with the use of Aluminium Profile.
                  </li>
                  <li>
                    Easy for any Operation as each Panel has a unique
                    intermediate locking System which facilitates to remove the
                    Single Panel or Add on to the Panel is very easily possible.
                  </li>
                  <li>
                    All our Sandwich Panels / Partitions are available in solid
                    and with factory fitted View panel which are supplied for
                    ready Installation on site.
                  </li>
                  <li>
                    Top and Bottom profiles are of Aluminium to avoid rusting
                    and have along term durability.
                  </li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </div>
      </section>
      <section className="keywords">
        <div className="container">
          <div className="leftheading">
            <span className="subhead">Keywords</span>
            <h2>
              Keyword Related to <span className="boldheading"> Product</span>
            </h2>
          </div>
          <div className="keywordslist">
            <h4>Clean Room Puf Panel</h4>
            <h4>Polyurethane Sandwich Panels</h4>
            <h4>Rockwool Sandwich Panel Manufacturers in India</h4>
            <h4>Insulated Sandwich Panel Manufacturers in India</h4>
            <h4>PUF Sandwich Panel Manufacturer in India</h4>
            <h4>Clean room Puf Sandwich Panels</h4>
            <h4>Clean Room Sandwich Panels</h4>
            <h4>Clean Room Insulated Panel</h4>
            <h4>Clean Room Insulation Panels price</h4>
            <h4>Clean Room Panel Manufacturers in Faridabad</h4>
            <h4>Clean Room Panel Manufacturers in Haryana</h4>
            <h4>Clean Room Panel Manufacturers in Delhi - NCR</h4>
            <h4>Clean Room Panel Manufacturers in india</h4>
            <h4>Clean Room Puf Panels Suppliers</h4>
            <h4>Clean Room Panel Price</h4>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="querypopup"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Quick Product Enquiry Form
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="product row">
                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Name"
                      className="input-field"
                      type="text"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Name
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Number"
                      className="input-field"
                      type="tel"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Number
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Email"
                      className="input-field"
                      type="email"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Email
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      value="Clean Room Glass View Panels"
                      disabled
                      className="selectedproduct input-field"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12 group-form">
                  <button
                    name="submit"
                    type="submit"
                    className="submitbtn"
                  >
                    Send Enquiry
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"></path>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
