import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination, Navigation, Autoplay, EffectCreative } from "swiper";
import { NavLink } from "react-router-dom";


export default function Productslider() {
  return (
    <>
      <section className="products">
        <div className="container">
          <div className="centerheading">
            <span className="mainhead">Products </span>
            <h2>Our Main Products</h2>
          </div>
          <div className="proslidercontainer">
          <Swiper
        slidesPerView={1}
        spaceBetween={0}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
          stopOnLastSlide:false,
        }}
        navigation={true} 
            centeredSlides={false}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className="productslider"
      >
      
        <SwiperSlide>
              <div className="product-card">
                <div className="propic">
                <img src="assets/images/products/wall-partition.jpg" width="100%" alt="Products of Viraaj Extrutech" />
                </div>
                <div className="procardbio">
                <h3><NavLink className="cardlink" to="/Wallpartition"> Wall Celing Panels</NavLink></h3>
                  <p>Viraaj Extrutech a prominent manufacturer and supplier of Wall Celing Panels in India .</p>
                  <NavLink className="mainbtn" to="/Wallpartition"> View More</NavLink>
                </div>
              </div> </SwiperSlide>
          <SwiperSlide>
          <div className="product-card">
          <div className="propic">
          <img src="assets/images/products/flush-doors.jpg" width="100%" alt="Products of Viraaj Extrutech" />
          </div>
          <div className="procardbio">
          <h3><NavLink className="cardlink" to="/Flushdoors">Modular Flush Doors</NavLink></h3>
          <p>Viraaj Extrutech a prominent manufacturer and supplier of Modular Flush Doors in India .</p>
          <NavLink className="mainbtn" to="/Flushdoors"> View More</NavLink>

          </div>
          </div>
         
          </SwiperSlide>
          <SwiperSlide>
          <div className="product-card">
          <div className="propic">
          <img src="assets/images/products/ceiling-panel.jpg" width="100%" alt="Products of Viraaj Extrutech" />
          </div>
          <div className="procardbio">
          <h3><NavLink className="cardlink" to="/Ceilingpanel">Walk-in Ceiling Panel</NavLink></h3>
          <p>Viraaj Extrutech a prominent manufacturer and supplier of Walk-in Ceiling Panel in India .</p>
          <NavLink className="mainbtn" to="/Ceilingpanel"> View More</NavLink>

          </div>
          </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="product-card">
          <div className="propic">
          <img src="assets/images/products/glass-panel.jpg" width="100%" alt="Products of Viraaj Extrutech" />
          </div>
          <div className="procardbio">
          <h3><NavLink className="cardlink" to="/Glasspanels">Glass View Panels</NavLink></h3>
          <p>Viraaj extrutech is actively engrossed in manufacturing a broad range of the glazed view panels.</p>
          <NavLink className="mainbtn" to="/Glasspanels"> View More</NavLink>

          </div>
          </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="product-card">
          <div className="propic">
          <img src="assets/images/products/lab-furniture.jpg" width="100%" alt="Products of Viraaj Extrutech" />
          </div>
          <div className="procardbio">
          <h3><NavLink className="cardlink" to="/Labfurniture">Laboratory Furniture</NavLink></h3>
          <p>Viraaj Extrutech a prominent manufacturer and supplier of Laboratory Furniture in India .</p>

          <NavLink className="mainbtn" to="/Labfurniture"> View More</NavLink>

          </div>
          </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="product-card">
          <div className="propic">
          <img src="assets/images/products/equipment.jpg" width="100%" alt="Products of Viraaj Extrutech" />
          </div>
          <div className="procardbio">
          <h3><NavLink className="cardlink" to="/Equipments">Clean Rooms Equipments</NavLink></h3>
          <p>Viraaj Extrutech a prominent manufacturer and supplier of clean room equipment in India .</p>
          <NavLink className="mainbtn" to="/Equipments"> View More</NavLink>

          </div>
          </div>
          </SwiperSlide>
        
      
      </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
