import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay } from "swiper";
import "swiper/css/navigation";
import Headerbanner from "../components/Headerbanner";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { BsFillSendFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";

export default function Equipments() {
  return (
    <>
      <Helmet>
        <title>Clean Room Equipments | Viraaj Extrutech India</title>
      </Helmet>
      <Headerbanner />
      <section className="breadcrumb">
        <div className="breadtitle">
          <h2 className="page-title">
            Clean Room Equipments
          </h2>
        </div>
        <div className="bread-bar">
          <ul className="breadlist">
            <li>
              <NavLink className="breadlinks" to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink className="breadlinks" to="/Products">
                Products
              </NavLink>
            </li>
            <li>
              <NavLink className="breadlinks" to="/Equipments">
              Clean Room Equipments
              </NavLink>
            </li>
          </ul>
        </div>
      </section>
      <section className="product-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="singleslider">
                <Swiper
                  navigation={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                  }}
                  loop={true}
                  modules={[ Navigation, Autoplay ]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="proimg">
                      <img
                        src="assets/images/products/equipment-1.webp"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="proimg">
                      <img
                        src="assets/images/products/equipment-2.webp"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="proimg">
                      <img
                        src="assets/images/products/equipment-3.webp"
                        width="100%"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="product-info">
                <div className="leftheading">
                  <span className="subhead">Partition Panels</span>
                  <h2>
                  Clean Room   <span className="boldheading"> Equipments </span>
                  </h2>
                </div>
                <p>
                  Pristine clean room equipment is essential to maintain the contamination free environment, and to meet the demanding requirement, Viraaj Extrutech, a prominent manufacturer and supplier of clean room equipment in India has made up a world class quality range of the clean room equipment that strongly meets all the clean room standards. Our manufactured range of the clean room equipment includes a SS Pass Box, Laminar Air Flow Unit and Clean Room Air Shower.
                </p>
                <button
                  type="button"
                  className="productquery"
                  data-bs-toggle="modal"
                  data-bs-target="#querypopup"
                >
                  Send Enquiry
                  <BsFillSendFill />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product-description">
        <div className="container">
          <Tabs
            defaultActiveKey="Description"
            transition={true}
            id="noanim-tab-example"
          >
            <Tab eventKey="Description" title="Desription">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Clean Room Equipments </h2>
                </div>
                <p>
                Pristine clean room equipment is essential to maintain the contamination free environment, and to meet the demanding requirement, Viraaj Extrutech, a prominent manufacturer and supplier of clean room equipment in India has made up a world class quality range of the clean room equipment that strongly meets all the clean room standards. Our manufactured range of the clean room equipment includes a SS Pass Box, Laminar Air Flow Unit and Clean Room Air Shower.
                </p>
                <p>
                All the clean room equipment produced by us are made from Stainless Steel material, which consumes less power and ensures efficient and unfailing performance spanning the life. The Stainless Steel material provides superior resistance against water, chemicals and corrosion, whilst the smooth surface of the equipment prevents accumulation of the bacteria/fungus and makes it easy to sanitize and maintain the utmost hygiene level essential in the clean room. All the parts of our clean room equipment are easy to clean, renew and replace that seemingly increases the overall productivity of the operation.
                </p>
               
                <div className="underheading">
                  <h3>Custom Solution is Our Standard</h3>
                </div>
               
               
              </div>
            </Tab>
            <Tab eventKey="Technical" title="Technical ">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Technical </h2>
                </div>
                <table className="detail-table">
                  <tbody>
                    <tr>
                      <th>Build Quality</th>
                      <td>Aluminium</td>
                    </tr>
                    <tr>
                      <th>Material Brand</th>
                      <td>Various Such as Tata.</td>
                    </tr>
                    <tr>
                      <th>Standard Size</th>
                      <td>Depends on Requirement</td>
                    </tr>
                    <tr>
                      <th>Other Sizes</th>
                      <td>
                        1200 x 600mm; 915 x 610mm, 600 x 600mm; 595 x 595mm
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab eventKey="Highlights" title="Highlights">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Highlights</h2>
                </div>
               <p>
               Our complete inventory of the clean room equipment is tested and complies with all the international quality regulations of the clean room, which customers can access in a standard size as well as customized size at the best prices in India as well as overseas. Our finest range of the clean room equipment is thriving in every nook, which includes Gujarat, Hyderabad, Mumbai, Assam, Himachal Pradesh and foreign countries like Nigeria, Algeria, Ghana, Egypt, Kenya, Turkey, Mexico, Brazil, Malaysia, Bangladesh, Nepal, Vietnam, Thailand, and more.
               </p>
              </div>
            </Tab>
            <Tab eventKey="Benefits" title="Benefits">
              <div className="tabcont slide-fwd-top">
                <div className="underheading">
                  <h2>Benefits</h2>
                </div>
                <ul className="specslist">
                  <li>
                    All panels are insulated with polyurethane/ PIR / Rock Wool
                    insulation and as per customers requirements.
                  </li>
                  <li>
                    Better Flexibility, Durability, Aesthetic and Elegant
                    appearance with the use of Aluminium Profile.
                  </li>
                  <li>
                    Easy for any Operation as each Panel has a unique
                    intermediate locking System which facilitates to remove the
                    Single Panel or Add on to the Panel is very easily possible.
                  </li>
                  <li>
                    All our Sandwich Panels / Partitions are available in solid
                    and with factory fitted View panel which are supplied for
                    ready Installation on site.
                  </li>
                  <li>
                    Top and Bottom profiles are of Aluminium to avoid rusting
                    and have along term durability.
                  </li>
                </ul>
              </div>
            </Tab>
          </Tabs>
        </div>
      </section>
      <section className="keywords">
        <div className="container">
          <div className="leftheading">
            <span className="subhead">Keywords</span>
            <h2>
              Keyword Related to <span className="boldheading"> Product</span>
            </h2>
          </div>
          <div className="keywordslist">
            <h4>Clean Room Puf Panel</h4>
            <h4>Polyurethane Sandwich Panels</h4>
            <h4>Rockwool Sandwich Panel Manufacturers in India</h4>
            <h4>Insulated Sandwich Panel Manufacturers in India</h4>
            <h4>PUF Sandwich Panel Manufacturer in India</h4>
            <h4>Clean room Puf Sandwich Panels</h4>
            <h4>Clean Room Sandwich Panels</h4>
            <h4>Clean Room Insulated Panel</h4>
            <h4>Clean Room Insulation Panels price</h4>
            <h4>Clean Room Panel Manufacturers in Faridabad</h4>
            <h4>Clean Room Panel Manufacturers in Haryana</h4>
            <h4>Clean Room Panel Manufacturers in Delhi - NCR</h4>
            <h4>Clean Room Panel Manufacturers in india</h4>
            <h4>Clean Room Puf Panels Suppliers</h4>
            <h4>Clean Room Panel Price</h4>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="querypopup"
        tabindex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Quick Product Enquiry Form
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="product row">
                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Name"
                      className="input-field"
                      type="text"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Name
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Number"
                      className="input-field"
                      type="tel"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Number
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      placeholder="Enter Email"
                      className="input-field"
                      type="email"
                      required
                    />
                    <label for="input-field" className="input-label">
                      Email
                    </label>
                    <span className="input-highlight"></span>
                  </div>
                </div>

                <div className="col-lg-12 group-form">
                  <div className="input-container">
                    <input
                      value="Clean Room Equipments"
                      disabled
                      className="selectedproduct input-field"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-12 group-form">
                  <button
                    name="submit"
                    type="submit"
                    className="submitbtn"
                  >
                    Send Enquiry
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"></path>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
