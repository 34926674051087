import React from 'react'

export default function Usp() {
    return (
        <>
            <section className="usp">
                <div className="container">
                    <div className="centerheading">
                        <span className="subhead">Our Application Areas</span>
                        <h2>Our USP & <span className="boldheading"> Core</span></h2>
                    </div>
                    <div className="row">

                        <div className="col-lg-4 mb-50">
                            <div className="usp-card">
                                <div className="usp-text">
                                    <h3>Our infrastructure</h3>
                                    <p>Our infrastructure is equipped with modern
                                     amenities and installations that aid in the
                                      process of smooth business functioning.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-50">
                            <div className="usp-card">
                                <div className="usp-text">
                                    <h3>On Time Delivery</h3>
                                    <p>Cold rooms for semi-conductors, electronic equipment,
                                        precision machines, aerospace, pharmaceutical and many
                                        other industries</p>
                                </div>
                            
                            </div>
                        </div>
                        <div className="col-lg-4 mb-50">
                            <div className="usp-card">
                                <div className="usp-text">
                                    <h3>Our Build Quality</h3>
                                    <p>We Provide Best Quality for our clients in All types of Cold and Clean Rooms, Modular Cold Rooms, Ceiling Panles .   </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
