import React from 'react'

export default function Clientele() {
  return (
   <>
    <section className="clientele">
        <div className="container">
        <div className="container">
  <div className="row">
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/amul.png" alt="" />
  </div>
  </div>
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/mcan.png" alt="" />
  </div>
  </div>
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/lupin.png" alt="" />
  </div>
  </div>
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/sunpharma.png" alt="" />
  </div>
  </div>
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/drreddy.png" alt="" />
  </div>
  </div>
    <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/alkem.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/biocon.png" alt="" />
  </div>
  </div>
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/britania.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/cipla.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/ds-group.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/gsk.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/haldiram.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/mankind.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/mdh.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/mother-dairy.png" alt="" />
  </div>
  </div>
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/mtr.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/nestle.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/parle.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/patanjali.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/serum.png" alt="" />
  </div>
  </div>

  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/torque.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/torrent.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/veeba.png" alt="" />
  </div>
  </div>
  
  <div className="col-lg-2 col-md-3 col-sm-6 col-6">
  <div className="client">
    <img src="assets/images/clientele/zydus.png" alt="" />
  </div>
  </div>


 
  <div className="col-12 text-center">
  <a href="#" id="loadMore">Load More</a>
  </div>
</div>
</div>
        </div>
    </section>
   </>
  )
}
