import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import Projects from './pages/Projects';
import Products from './pages/Products';
import Contact from './pages/Contact';
import About from './pages/About';
import Wallpartition from './pages/Wallpartition';
import Equipments from './pages/Equipments';
import Labfurniture from './pages/Labfurniture';
import Glasspanels from './pages/Glasspanels';
import Ceilingpanel from './pages/Ceilingpanel';
import Flushdoors from './pages/Flushdoors';
import ScrollToTop from './ScrollToTop';



function App() {
  return (
    <>
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route path="/Home" element={<Home />}></Route>
        </Routes>
        <Routes>
          <Route path="/Index" element={<Home />}></Route>
        </Routes>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/wallpartition" element={<Wallpartition />}></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/Products" element={<Products />}></Route>
          <Route path="/Projects" element={<Projects />}></Route>
          <Route path="/Equipments" element={<Equipments />}></Route>
          <Route path="/Labfurniture" element={<Labfurniture />}></Route>
          <Route path="/Glasspanels" element={<Glasspanels />}></Route>
          <Route path="/Ceilingpanel" element={<Ceilingpanel />}></Route>
          <Route path="/Flushdoors" element={<Flushdoors />}></Route>

        </Routes>

      </BrowserRouter>

    </>
  );
}

export default App;
